// const header = document.querySelector(".header");
// const first = document.querySelector(".services");
// const headerHeight = header.offsetHeight;
// const firstHeight = first.offsetHeight;
// let lastScrollTop = 0;

// window.addEventListener("scroll", () => {
//   let scrollDistance = window.scrollY;

//   if (scrollDistance >= firstHeight + headerHeight) {
//     header.classList.add("header--fixed");
//     first.style.marginTop = null;
//   } else {
//     header.classList.remove("header--fixed");
//     first.style.marginTop = null;
//   }

//   lastScrollTop = scrollDistance;
// });
